import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Fade from "@mui/material/Fade";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import TextField from "@mui/material/TextField";
import { Control, Controller, useFormState } from "react-hook-form";
import { ICandidate } from "../../data/Candidates";
import useStatuses from "../../data/Statuses";
import useSectors from "../../data/Sectors";
import useTechnicalSkills from "../../data/TechnicalSkills";
import useLevels from "../../data/Levels";

const dateOptions: Intl.DateTimeFormatOptions = {
  year: "numeric",
  month: "numeric",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
};

interface ICandidateOfficeFormProps {
  control: Control<ICandidate>;
  disabled?: boolean;
  candidateLoading?: boolean;
}

export default function CandidateOfficeForm({
  control,
  disabled,
  candidateLoading,
}: ICandidateOfficeFormProps) {
  const { errors } = useFormState({
    control,
  });
  const [sectors, sectorsLoading] = useSectors();
  const [technicalSkills, technicalSkillsLoading] = useTechnicalSkills();
  const [levels, levelsLoading] = useLevels();

  const [statuses, statusesLoading] = useStatuses();

  const loading =
    sectorsLoading ||
    technicalSkillsLoading ||
    levelsLoading ||
    statusesLoading ||
    (candidateLoading ?? false);

  return (
    <>
      <Tabs
        value={0}
        indicatorColor="primary"
        textColor="primary"
        sx={{ borderBottom: 1, borderColor: "grey.300" }}
      >
        <Tab label="Office Use" />
      </Tabs>
      <Fade in={!loading} style={{ transitionDelay: "300ms" }}>
        <Box>
          {loading ? null : (
            <>
              <Controller
                name="status"
                control={control}
                render={({ field }) => {
                  return (
                    <FormControl
                      sx={
                        statuses.get(Number(field.value)) === "Placed"
                          ? {
                              outline: "0.2rem solid red",
                              outlineOffset: "-0.5rem",
                            }
                          : {}
                      }
                      fullWidth
                      variant="filled"
                      margin="dense"
                      error={!!errors.status}
                    >
                      <InputLabel id="status">Status</InputLabel>
                      <Select labelId="status" {...field} disabled={disabled}>
                        {Array.from(statuses, ([key, value]) => (
                          <MenuItem key={key} value={key}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                      {errors.status && (
                        <FormHelperText>
                          {errors.status?.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  );
                }}
              />
              <Controller
                name="sectors"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(sectors.keys())}
                    getOptionLabel={(option) => sectors.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={sectors.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Sectors"
                        variant="filled"
                        margin="dense"
                        error={!!errors.sectors}
                        helperText={(errors.sectors as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="technical_skills"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(technicalSkills.keys())}
                    getOptionLabel={(option) =>
                      technicalSkills.get(option) ?? ""
                    }
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={technicalSkills.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Skills"
                        variant="filled"
                        margin="dense"
                        error={!!errors.technical_skills}
                        helperText={(errors.technical_skills as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="levels"
                control={control}
                render={({ field }) => (
                  <Autocomplete
                    {...field}
                    disabled={disabled}
                    multiple
                    options={Array.from(levels.keys())}
                    getOptionLabel={(option) => levels.get(option) ?? ""}
                    onChange={(_e, v) => field.onChange(v)}
                    fullWidth
                    renderTags={(values, getTagProps) =>
                      values.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={levels.get(option)}
                          {...getTagProps({ index })}
                          key={option}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Levels"
                        variant="filled"
                        margin="dense"
                        error={!!errors.levels}
                        helperText={(errors.levels as any)?.message}
                      />
                    )}
                  />
                )}
              />
              <Controller
                name="created_by"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Created By"
                    variant="filled"
                    margin="dense"
                    disabled
                    fullWidth
                    {...field}
                  />
                )}
              />
              <Controller
                name="modified"
                control={control}
                render={({ field }) => (
                  <TextField
                    label="Last Edited"
                    variant="filled"
                    margin="dense"
                    disabled
                    fullWidth
                    {...field}
                    value={new Date(field.value ?? 0).toLocaleDateString(
                      "en-GB",
                      dateOptions
                    )}
                  />
                )}
              />
            </>
          )}
        </Box>
      </Fade>
    </>
  );
}
