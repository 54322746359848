import axios from "axios";
import React from "react";
import * as yup from "yup";
import { INotes, noteSchema } from "./Notes";

export interface ICandidate extends INotes {
  id?: number;
  name: string;
  created_by?: string;
  modified?: Date;
  title: number | null;
  status?: string | number;
  id_number?: string;
  phone_number_primary: string;
  phone_number_secondary?: string;
  email: string;
  email_secondary?: string;
  address: string;
  notice_period: number;
  gender: number | null;
  valid_work_permit: boolean;
  qualifies_for_bee: boolean;
  permanent_resident: boolean;
  has_own_car: boolean;
  salary: string | number;
  nationality: number | null;
  driving_licence?: number | null;
  sectors?: number[];
  technical_skills?: number[];
  levels?: number[];
  areas: number[];
  languages: number[];
  qualifications: number[];
  highest_qualification?: string;
  folder?: string;
}

export interface ICandidateList {
  count: number;
  next: string | null;
  previous: string | null;
  results: ICandidateListItem[];
}

export interface ICandidateListItem {
  id: number;
  name: string;
  status: string;
  modified: string;
  created: string;
  phone_number_primary: string;
  email: string;
  notice_period: number;
  valid_work_permit: boolean;
  qualifies_for_bee: boolean;
  permanent_resident: boolean;
  has_own_car: boolean;
  salary: number;
  nationality: string;
  driving_licence: string;
  categories: { id: number; name: string }[];
  skills: { id: number; name: string }[];
  areas: { id: number; name: string }[];
  languages: { id: number; name: string }[];
  experience: { id: number; name: string }[];
  sectors: { id: number; name: string }[];
  technical_skills: { id: number; name: string }[];
  levels: { id: number; name: string }[];
  qualifications: { id: number; name: string }[];
  highest_qualification: string;
  migration_exception: boolean;
  folder: string;
}

export const candidateSchema: yup.SchemaOf<ICandidate> = yup.object({
  id: yup.number().integer().optional(),
  name: yup.string().required("Name is a required field."),
  created_by: yup.string().optional(),
  modified: yup.date().optional(),
  title: yup.number().required().typeError("Title is a required field."),
  status: yup.string().optional(),
  id_number: yup.string().optional(),
  phone_number_primary: yup
    .string()
    .required("Phone (Primary) is a required field."),
  phone_number_secondary: yup.string().optional(),
  email: yup.string().email().required("Email address is a required field."),
  email_secondary: yup.string().optional(),
  address: yup.string().required("Address is a required field."),
  notice_period: yup.number().integer().min(0).max(12).required(),
  gender: yup.number().required().typeError("Gender is a required field."),
  qualifies_for_bee: yup.bool().required(),
  valid_work_permit: yup.bool().required(),
  permanent_resident: yup.bool().required(),
  has_own_car: yup.bool().required(),
  salary: yup
    .number()
    .positive()
    .required()
    .typeError("Salary is a required field - please enter a valid number."),
  nationality: yup
    .number()
    .required()
    .typeError("Nationality is a required field."),
  driving_licence: yup
    .number()
    .optional()
    .typeError("Driving Licence is a required field."),
  sectors: yup.array().of(yup.number().integer().required()).optional(),
  technical_skills: yup
    .array()
    .of(yup.number().integer().required())
    .optional(),
  levels: yup.array().of(yup.number().integer().required()).optional(),
  areas: yup
    .array()
    .of(yup.number().integer().required())
    .min(1, "At least one value for areas is required."),
  languages: yup
    .array()
    .of(yup.number().integer().required())
    .min(1, "At least one value for experience is required."),
  qualifications: yup.array().of(yup.number().integer().required()),
  highest_qualification: yup.string().optional(),
  folder: yup.string().optional(),
  notes: yup.array().of(noteSchema),
});

export const salaryFormatter = new Intl.NumberFormat("en-ZA", {
  style: "currency",
  currency: "ZAR",
  minimumFractionDigits: 0,
});

export function useCandidates(query: string): [ICandidateList | null, boolean] {
  const [candidates, setCandidates] = React.useState<ICandidateList | null>(
    null
  );
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<ICandidateList>(`/candidates/?${query}`)
      .then((response) => {
        setCandidates(response.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [query]);

  return [candidates, loading];
}

export function useCandidate(
  id: string
): [
  ICandidate | null,
  React.Dispatch<React.SetStateAction<ICandidate | null>>,
  boolean
] {
  const [candidate, setCandidate] = React.useState<ICandidate | null>(null);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<ICandidate>(`/candidates/${id}/`)
      .then((response) => {
        setCandidate(response.data);
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, [id]);
  return [candidate, setCandidate, loading];
}
