import React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Chip from "@mui/material/Chip";
import FormControlLabel from "@mui/material/FormControlLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import SearchIcon from "@mui/icons-material/Search";
import { Control, Controller } from "react-hook-form";
import { ICandidateFilter } from "./CandidateListPage";
import useNationalities from "../../data/Nationalities";
import useLanguages from "../../data/Languages";
import useAreas from "../../data/Areas";
import useDrivingLicences from "../../data/DrivingLicences";
import NameAutocomplete from "../misc/NameAutocomplete";
import useNoticePeriods from "../../data/NoticePeriod";
import useStatuses from "../../data/Statuses";
import useSectors from "../../data/Sectors";
import useTechnicalSkills from "../../data/TechnicalSkills";
import useLevels from "../../data/Levels";

interface CandidateListFilterProps {
  control: Control<ICandidateFilter>;
}

export default function CandidateListFilter({
  control,
}: CandidateListFilterProps) {
  const [nationalities] = useNationalities();
  const [sectors, sectorsLoading] = useSectors();
  const [technicalSkills, technicalSkillsLoading] = useTechnicalSkills();
  const [levels, levelsLoading] = useLevels();
  const [languages, languagesLoading] = useLanguages();
  const [areas, areasLoading] = useAreas();
  const [drivingLicences, drivingLicencesLoading] = useDrivingLicences();
  const [noticePeriods, noticePeriodsLoading] = useNoticePeriods();
  const [statuses, statusesLoading] = useStatuses();

  return (
    <>
      <Toolbar sx={{ borderBottom: 1, borderColor: "grey.300" }}>
        <Typography variant="h6" component="div">
          Filters
        </Typography>
      </Toolbar>
      <Box sx={{ pl: 2, pr: 2, pb: 2 }}>
        <Controller
          name="search"
          control={control}
          render={({ field }) => (
            <NameAutocomplete
              section="candidates"
              name={field.name}
              onChange={field.onChange}
              value={field.value}
            />
          )}
        />
        <Controller
          name="nationality"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(nationalities.keys())}
              getOptionLabel={(option) => nationalities.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Nationality"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="sectors"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(sectors.keys())}
              getOptionLabel={(option) => sectors.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={sectorsLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={sectors.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sectors"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="technical_skills"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(technicalSkills.keys())}
              getOptionLabel={(option) => technicalSkills.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={technicalSkillsLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={technicalSkills.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Skills"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="levels"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(levels.keys())}
              getOptionLabel={(option) => levels.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={levelsLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={levels.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Levels"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="languages"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(languages.keys())}
              getOptionLabel={(option) => languages.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={languagesLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={languages.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Languages"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="areas"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              multiple
              options={Array.from(areas.keys())}
              getOptionLabel={(option) => areas.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={areasLoading}
              renderTags={(values, getTagProps) =>
                values.map((option, index) => (
                  <Chip
                    size="small"
                    variant="outlined"
                    label={areas.get(option)}
                    {...getTagProps({ index })}
                    key={option}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Areas"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="driving_licence"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(drivingLicences.keys())}
              getOptionLabel={(option) => drivingLicences.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={drivingLicencesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Driving Licence"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Stack direction="row" sx={{ gap: 1 }}>
          <Controller
            name="min_salary"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Min Salary"
                variant="filled"
                margin="dense"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R</InputAdornment>
                  ),
                }}
              />
            )}
          />
          <Controller
            name="max_salary"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Max Salary"
                variant="filled"
                margin="dense"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R</InputAdornment>
                  ),
                }}
              />
            )}
          />
        </Stack>
        <Controller
          name="max_notice_period"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(noticePeriods.keys())}
              getOptionLabel={(option) => noticePeriods.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={noticePeriodsLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Maximum Notice Period"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Controller
          name="status"
          control={control}
          render={({ field }) => (
            <Autocomplete
              {...field}
              options={Array.from(statuses.keys())}
              getOptionLabel={(option) => statuses.get(option) ?? ""}
              onChange={(_e, v) => field.onChange(v)}
              fullWidth
              loading={statusesLoading}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Status"
                  variant="filled"
                  margin="dense"
                />
              )}
            />
          )}
        />
        <Stack direction="row" justifyContent="space-between" spacing={0}>
          <Controller
            name="permanent_resident"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="Resident"
                labelPlacement="top"
              />
            )}
          />
          <Controller
            name="qualifies_for_bee"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="BEE"
                labelPlacement="top"
              />
            )}
          />
          <Controller
            name="valid_work_permit"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={(e) => field.onChange(e.target.checked)}
                    checked={field.value}
                    color="primary"
                  />
                }
                label="Permit"
                labelPlacement="top"
              />
            )}
          />
        </Stack>
        <Box sx={{ mt: 1 }}>
          <Button
            type="submit"
            startIcon={<SearchIcon />}
            variant="contained"
            fullWidth
          >
            Search
          </Button>
        </Box>
      </Box>
    </>
  );
}
