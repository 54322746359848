import axios from "axios";
import React from "react";

interface ITechnicalSkill {
  id: number;
  name: string;
}

export default function useTechnicalSkills(): [Map<number, string>, boolean] {
  const [technicalSkills, setTechnicalSkills] = React.useState<
    Map<number, string>
  >(new Map());
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    axios
      .get<ITechnicalSkill[]>("/technicalskills/")
      .then((response) => {
        setTechnicalSkills(new Map(response.data.map((x) => [x.id, x.name])));
        setLoading(false);
      })
      .catch((error) => console.log(error));
  }, []);

  return [technicalSkills, loading];
}
